<template>
  <div>
    <el-button
      type="success"
      v-if="allowMinting"
      :disabled="isPaused"
      @click="doMinting()"
    >
      Mint {{ formatPrice() }}
    </el-button>
  </div>
</template>

<script>
  import { computed, defineComponent, ref, watch, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'

  import BaseGenArtContractService from '@/services/BaseGenArtContractService'

  export default defineComponent({
    name: 'MintButton',
    components: {},
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user, root },
      } = store

      const web3 = computed(() => contractState.web3)
      const contract = computed(() => contractState.contract)
      const walletAddress = computed(() => user.walletAddress)
      const selectedProject = computed(() => root.selectedProject)

      const price = ref('')

      const supplyLeft = ref(0)
      const allowMinting = ref(true)

      const isPaused = ref(false)

      const contractRef = ref(contract)
      const walletAddressRef = ref(walletAddress)
      const notificationTime = process.env.VUE_APP_NOTIFICATION_DURATION

      const doMinting = async () => {
        dispatch('setLoading', true)
        const baseGenArtContractService = new BaseGenArtContractService(
          web3.value,
          contract.value
        )

        let result = null

        const params = {
          price: price.value,
          contractAddress: selectedProject.value?.configuration.contractAddress,
          walletAddress: walletAddress.value,
        }

        try {
          result = await baseGenArtContractService.publicMint(params)

          console.log('result: ', result)
          ElNotification({
            title: 'Success',
            message: 'Minting successful.',
            type: 'success',
            duration: notificationTime,
          })

          await retrieveContractInformation()
        } catch (e) {
          if (e.code === 4001) {
            ElNotification({
              title: 'Error',
              message: 'Minting cancelled.',
              type: 'error',
              duration: notificationTime,
            })
          } else if (e.code === -32603) {
            ElNotification({
              title: 'Error',
              message: 'Error processing TX.',
              type: 'error',
              duration: notificationTime,
            })
          } else {
            ElNotification({
              title: 'Error',
              message: `Minting failed: ${e.message}`,
              type: 'error',
              duration: notificationTime,
            })
          }
        }

        dispatch('setLoading', false)
      }

      const setPrice = (contractInformation) => {
        price.value = contractInformation.publicPrice
      }

      const formatPrice = () => {
        if (price.value === '') {
          return ''
        }

        return price.value === 0 ? '(free)' : `(${price.value} eth)`
      }

      const retrieveContractInformation = async () => {
        await dispatch('setLoading', true)
        const baseGenArtContractService = new BaseGenArtContractService(
          web3.value,
          contract.value
        )

        const contractInformation =
          await baseGenArtContractService.readContract(walletAddress.value)

        setPrice(contractInformation)
        supplyLeft.value = Number.parseInt(contractInformation.supplyLeft)
        isPaused.value = contractInformation.isPaused

        await dispatch('setLoading', false)
      }

      onMounted(() => {
        if (contractRef.value) {
          retrieveContractInformation()
        }
      })

      watch([contractRef, walletAddressRef], () => {
        if (contractRef.value && walletAddressRef.value) {
          retrieveContractInformation()
        }
      })

      return {
        allowMinting,
        isPaused,
        doMinting,
        formatPrice,
      }
    },
  })
</script>
