<template>
  <div class="norte">
    <div v-if="!addressStateRef">
      <div class="connect-wallet-alert-div">
        <el-alert
          class="connect-wallet-alert"
          :closable="false"
          effect="dark"
          type="warning"
          show-icon
        >
          <h2>Please connect your wallet to use BaseGenArt</h2>
        </el-alert>
      </div>
    </div>
    <div v-if="addressStateRef" class="main-container">
      <el-row>
        <el-col>
          <div
            class="grid-content bg-purple-light"
            style="margin-bottom: 20%"
          ></div>
          <div class="s1-project-btns">
            <div class="desktop-mint-btn-container">
              <MintButton />
            </div>
            <el-link href="/tokens/base-gen-art" :underline="false">
              <el-button type="primary">
                Browse Gallery
                <el-icon class="el-icon--right"><Grid /></el-icon>
              </el-button>
            </el-link>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { Grid } from '@element-plus/icons'
  import { computed, ref, onBeforeMount, watch } from 'vue'
  import { useStore } from 'vuex'
  import MintButton from '@/components/projects/base-gen-art/base-gen-art-mint/mint-button'

  import { selectProjectContract } from '@/services/ProjectService'

  export default {
    name: 'BaseGenArt',

    components: {
      MintButton,
      Grid,
    },

    setup() {
      // Hooks
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user },
      } = store

      // Read from state
      const addressState = computed(() => user.walletAddress)
      const web3 = computed(() => contractState.web3)

      const addressStateRef = ref(addressState)

      onBeforeMount(async () => {
        dispatch('setEditMode', false)

        if (web3.value) {
          await selectProjectContract('base-gen-art', web3.value, dispatch)
        }
      })

      watch([web3], async () => {
        await selectProjectContract('base-gen-art', web3.value, dispatch)
      })

      return {
        addressStateRef,
      }
    },
  }
</script>

<style scoped>
  .norte {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-container {
    max-width: 1500px;
  }

  .left-container,
  .right-container {
    padding: 2px;
    width: 100%;
    height: 100%;
  }

  .s1-project-btns {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .desktop-mint-btn-container {
    margin: 20px;
  }
</style>
